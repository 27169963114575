'use strict';
import 'amfe-flexible';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App';
// 组件
import CommonUtils from './common/common';
import Ajax from './common/ajax';
import Modules from './common/store';

// 默认路由配置
import router from './common/router';

import './mock';

// promise.polyfill()
// install
Vue.use(Vuex);
Vue.use(CommonUtils);
Vue.use(Ajax);

// 初始化路由（动态装配路由）
Vue.$common.loadRouter(router);
// Vuex
const store = new Vuex.Store({
    modules: Modules,
});
router.beforeEach(function (to, from, next) {
    // let hasAuth = Vue.$vux.common.hasAuth(to)
    // if (hasAuth == true || to.path === '/noauth' || to.path === '/login') {
    //   if (to.path === '/login') {
    //     Vue.$vux.common.removeUserAuthData()
    //   }
    //   next()
    //   return
    // }
    //
    // next({path: hasAuth})
    if (to.path === '/') {
        next({ path: 'index' });
        return;
    }
    // if (!(to.meta.auth == false)) {
    //   let status = localStorage.getItem('loc_status')
    //   if (status !== '1') {
    //     Vue.$vux.common.alert("审核未通过")
    //     return
    //   }
    // }
    next();
});

// FastClick.attach(document.body)

// Vue开启debug模式
Vue.config.debug = CommonUtils.isDebug;
Vue.config.productionTip = CommonUtils.isDebug;

// 挂载
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
