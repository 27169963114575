<template>
    <div class="container">
        <h4>DUANG!</h4>
        <h5>页面找不到了!!!!</h5>
        <!-- <div class="back">
                <button @click="refresh()">点击刷新</button>
            </div> -->
    </div>
</template>
<script>
    export default {
        data() {
            return {};
        },
        methods: {
            refresh() {
                if (window.frames.length !== parent.frames.length) {
                    parent.location.reload();
                } else {
                    location.reload();
                }
            },
        },
    };
</script>
<style lang="less" scoped>
    @import 'styles/core.less';
    .container {
        color: #fff;
        padding: 40px;
    }
</style>
