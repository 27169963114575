import Vue from 'vue';
import qs from 'qs';

/**
 *  公用
 */
export default {
    state: {
        storeId: null,
        pageStatus: 0, // 0:隐藏 1：加载 2：错误
        errorMessage: '',
        direction: 'forward',
        listSelectorData: [],
        cityData: [],
    },
    mutations: {
        updatePageStatus(state, payload) {
            state.pageStatus = payload.pageStatus;
            if (payload.errorMessage) {
                state.errorMessage = payload.errorMessage;
            }
        },
        updateDirection(state, payload) {
            state.direction = payload.direction;
        },
        SET_LIST_SELECTOR_DATA(state, payload) {
            state.listSelectorData = payload.data;
        },
        GET_CITY_DATA(state, payload) {
            state.cityData = payload.items;
        },
        SET_STORE_ID(state, payload) {
            state.storeId = payload.storeId;
        },
    },
    actions: {
        operate({ commit }, payLoad) {
            Vue.http
                .get(payLoad.url, {
                    params: payLoad.params,
                    headers: { 'Show-Spinner': false },
                })
                .then(function(res) {
                    if (res.status === 200) {
                        Vue.$common.log(payLoad, res.data);
                        payLoad.callback(res.data.code, res.data);
                    } else {
                        payLoad.callback('error');
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    payLoad.error && payLoad.error(error);
                });
        },
        post({ commit }, payLoad) {
            // , {'Content-Type': 'application/x-www-form-urlencoded'}
            Vue.http
                .post(payLoad.url, qs.stringify(payLoad.params), {
                    'Show-Spinner': false,
                })
                .then(function(res) {
                    if (res.status === 200) {
                        Vue.$common.log(payLoad, res.data);
                        payLoad.callback(res.data.status, res.data);
                    } else {
                        payLoad.callback('error');
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    payLoad.error && payLoad.error(error);
                });
        },
        postJson({ commit }, payLoad) {
            Vue.http
                .post(payLoad.url, payLoad.params, {
                    'Show-Spinner': false,
                })
                .then(function(res) {
                    if (res.status === 200) {
                        Vue.$common.log(payLoad, res.data);
                        payLoad.callback(res.status, res.data.data);
                    } else {
                        payLoad.callback('error');
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    payLoad.error && payLoad.error(error);
                });
        },
        getCityData({ commit }, payload) {
            Vue.http.get('city').then(function(res) {
                if (res.status === 200) {
                    Vue.log(res.data);
                    commit('GET_CITY_DATA', res.data);
                    payload && payload.callback && payload.callback(res.data);
                }
            });
        },
        getListSelectorData({ commit }, payload) {
            Vue.http
                .get('common/basedata', {
                    params: {
                        id: payload.id,
                        type: payload.type,
                        subtype: payload.subtype,
                        st: payload.st,
                    },
                })
                .then(function(res) {
                    if (res.status === 200) {
                        Vue.log(res.data);
                        commit('SET_LIST_SELECTOR_DATA', res.data);
                        payload && payload.callback && payload.callback(res.data);
                    }
                });
        },
        setStoreId({ commit }, payload) {
            commit('SET_STORE_ID', payload);
        },
    },
    getters: {},
};
