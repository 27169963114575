import Vue from 'vue';
import Router from 'vue-router';
// 模板
import NotAuth from '../../noauth.vue';
import NotFound from '../../404.vue';
import Protocol from '../../protocol.vue';

Vue.use(Router);

// /*************规避重复点击 Start */
// //获取原型对象上的push函数
// const originalPush = Router.prototype.push;
// //修改原型对象中的push方法
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch((err) => err);
// };
// //修改原型对象中的replace方法
// Router.prototype.replace = function replace(location) {
//     return originalPush.call(this, location).catch((err) => err);
// };
// /*************规避重复点击 End */

const routers = [
    { path: '/noauth', name: 'notAuth', component: NotAuth, meta: { keepAlive: false, title: '无权限', anonymous: true } },
    { path: '/404', name: 'notFound', component: NotFound, meta: { keepAlive: false, title: '找不到当前页面', anonymous: true } },
    { path: '/protocol', name: 'protocol', component: Protocol, meta: { keepAlive: false, title: '隐私协议', anonymous: true } },
];
export default new Router({
    mode: 'history',
    routes: routers,
});
