<template>
  <div class="view">
    <div class="container">
      <h3>DUANG!</h3>
      <h1>页面会话已过期，请手动刷新或退出页面重新登录。</h1>
      <div class="back">
        <button @click="refresh()">点击刷新</button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {}
    },
    methods: {
      refresh () {
        if (window.frames.length !== parent.frames.length) {
          parent.location.reload()
        } else {
          location.reload()
        }
      }
    }
  }

</script>
<style scoped>
  .view {
    display: flex;
    justify-content: center;
    color: #999;
    height: 100%;
    padding: 0;
  }

  .view .container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  h1 {
    margin-top: 1rem;
  }

  .back button {
    margin-top: 3rem;
    padding: .6rem 3.5rem;
    border-radius: .5rem;
    background: #ccc;
    font-size: 1.4rem;
    border: none;
    color: #999;
  }

  .back button:hover {
    background: #ddd;
  }
</style>
