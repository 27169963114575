/**
 * Created by leekoha on 01/08/2017.
 */
import commonModule from './common';
import WxAppModule from './wxApp';

const modules = {
    common: commonModule,
    wxApp: WxAppModule,
};

export default modules;
