<template>
    <div id="app" class="main">
        <transition>
            <keep-alive v-if="$route.meta.keepAlive">
                <router-view></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
        </transition>
    </div>
</template>
<script>
  import {mapActions, mapState} from 'vuex';
  import Token from './common/utils/token';

  export default {
    name: 'app',
    data() {
      return {
        floatMenu: null,
      };
    },
    methods: {
      showLoading() {
        let loadings = document.querySelectorAll('.ems-loading');
        if (!this.$common.isEmptyObject(loadings)) {
          for (let loading of loadings) {
            loading.classList.add('show');
          }
        }
      },
      hideLoading() {
        let loadings = document.querySelectorAll('.ems-loading');
        if (!this.$common.isEmptyObject(loadings)) {
          for (let loading of loadings) {
            loading.classList.remove('show');
          }
        }
      },
      ...mapActions(['operate', 'setStoreId']),
    },
    computed: {
      ...mapState({
        pageStatus: (state) => state.common.pageStatus,
        errorMessage: (state) => state.common.errorMessage,
        direction: (state) => state.common.direction,
      }),
    },
    created() {
      let that = this;
      // 添加请求拦截器
      this.$http.interceptors.request.use(
        function (config) {
          // 在发送请求之前做些什么
          config.headers['X-Access-Token'] = Token.getToken(config);

          // config.headers['X-Access-Token'] = 'xxxxxxxxxxxxx';
          that.showLoading();
          return config;
        },
        function (error) {
          that.hideLoading();
          // 对请求错误做些什么
          return Promise.reject(error);
        }
      );
      // 添加响应拦截器
      this.$http.interceptors.response.use(
        function (response) {
          that.hideLoading();
          // 对响应数据做点什么

          return response;
        },
        function (error) {
          that.hideLoading();
          return Promise.reject(error);
        }
      );
    },
    mounted() {
      let that = this;
      window.onresize = () => {
        return (() => {
          that.wresize();
        })();
      };
    },
    updated() {
    },
  };
</script>
<style lang="less">
    @page-width: 1000px;

    html,
    body {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        background: #fff;
        font-family: ”微软雅黑”;
    }

    body {
        font-size: 14px;
        color: #333;
    }

    .view {
        width: 100%;
        height: 100vh;
        position: relative;
    }
</style>
