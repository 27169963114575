const ua = navigator.userAgent;
const isAndroid = /(Android);?[\s/]+([\d.]+)?/.test(ua);
const isIpad = /(iPad).*OS\s([\d_]+)/.test(ua);
const isIpod = /(iPod)(.*OS\s([\d_]+))?/.test(ua);
const isIphone = !isIpad && /(iPhone\sOS)\s([\d_]+)/.test(ua);
const isWechat = /micromessenger/i.test(ua);
const isApp = /com.env.manager/i.test(ua);

import RouterUtils from './router/utils';
const jsonAuthData = require('./auth.json');

const utils = {
    isDebug: process.env.NODE_ENV === 'development',
    install(Vue, options) {
        Vue.$common = Vue.prototype.$common = {
            log(tag, ...params) {
                if (Vue.config.debug) {
                    console.log('%c' + tag.url, 'color: red; font-weight: bold');
                    console.log(tag.params);
                    console.log(params);
                }
            },
            device() {
                return { isApp: isApp };
            },
            screenSize() {
                return {
                    w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                    h: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
                };
            },
            isNull(val) {
                return val === '' || val === undefined || val === null ? true : false;
            },
            toBool(val) {
                if (!val) return false;
                return /^true$/i.test(val);
            },
            merge() {
                var length = arguments.length;
                var target = arguments[0] || {};
                if (typeof target != 'object' && typeof target != 'function') {
                    target = {};
                }
                if (length == 1) {
                    target = this;
                    i--;
                }
                for (var i = 1; i < length; i++) {
                    var source = arguments[i];
                    for (var key in source) {
                        // 使用for in会遍历数组所有的可枚举属性，包括原型。
                        if (Object.prototype.hasOwnProperty.call(source, key)) {
                            if (this.isPlainObject(source[key])) {
                                target[key] = target[key] || {};
                                target[key] = {
                                    ...target[key],
                                    ...source[key],
                                };
                            } else {
                                target[key] = source[key];
                            }
                        }
                    }
                }
                return target;
            },
            showLoading() {
                let loadings = document.querySelectorAll('.ac-loading');
                if (!this.isEmptyObject(loadings)) {
                    for (let loading of loadings) {
                        loading.classList.add('show');
                    }
                }
            },
            hideLoading() {
                let loadings = document.querySelectorAll('.ac-loading');
                if (!this.isEmptyObject(loadings)) {
                    for (let loading of loadings) {
                        loading.classList.remove('show');
                    }
                }
            },
            isPlainObject(obj) {
                //判断是否非window和DOM对象的对象，
                if (!obj || obj.toString() !== '[object Object]' || obj.nodeType || obj.setInterval) {
                    return false;
                }

                //constructor是对创建对象的函数的引用（指针）。对于 Object 对象，该指针指向原始的 Object() 函数
                //判断obj是否具有isPrototypeOf属性，isPrototypeOf是挂在Object.prototype上的。通过字面量或自定义类（构造器）创建的对象都会继承该属性方法
                if (obj.constructor && !obj.hasOwnProperty('constructor') && !obj.constructor.prototype.hasOwnProperty('isPrototypeOf')) {
                    return false;
                }

                var key;
                for (key in obj) {
                }

                return key === undefined || obj.hasOwnProperty(key);
            },
            buildParams(params) {
                if (!params) {
                    return [];
                }
                return params;
            },
            invokeNativeMethod(name, callback, ...params) {
                params = this.buildParams(params);
                if (window.android && window.android[name]) {
                    if (callback) {
                        callback(window.android[name].apply(window.android, params));
                    } else {
                        window.android[name].apply(window.android, params);
                    }
                } else if (window.NativeBridge) {
                    NativeBridge.call(name, params, function (data) {
                        callback && callback(data);
                    });
                } else if (window.webkit && isIphone) {
                    var obj = { url: encodeURI(params[0]), title: params[1] };
                    //console.log(obj)
                    window.webkit.messageHandlers.openLevel2Fragment.postMessage(obj);
                } else {
                    callback && callback('error');
                }
                return null;
            },
            redirect(name, ...params) {
                params = this.buildParams(params);
                if (window.android && window.android[name]) {
                    window.android[name].apply(window.android, params);
                } else if (window.NativeBridge) {
                    NativeBridge.call(name, params);
                } else {
                    // window.location.href = testurl;
                    self.$router.push('/question/0');
                }
            },
            arrayAvg(call) {
                let sum = call.reduce((pre, cur) => pre + cur);
                return sum / call.length;
            },
            getStorage() {
                // 初始化权限菜单
                localStorage.setItem('user-auth', JSON.stringify(jsonAuthData));
                return localStorage;
            },
            formatDate: function (date, fmt) {
                if (!date) {
                    return null;
                }
                let type = Object.prototype.toString.call(date);
                if (type === '[object String]' || type === '[object Number]') {
                    date = new Date(date);
                }
                var o = {
                    'M+': date.getMonth() + 1,
                    'D+': date.getDate(),
                    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
                    'H+': date.getHours(),
                    'm+': date.getMinutes(),
                    's+': date.getSeconds(),
                    'q+': Math.floor((date.getMonth() + 3) / 3),
                    S: date.getMilliseconds(),
                };
                var week = {
                    0: '/u65e5',
                    1: '/u4e00',
                    2: '/u4e8c',
                    3: '/u4e09',
                    4: '/u56db',
                    5: '/u4e94',
                    6: '/u516d',
                };
                if (/(Y+)/.test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
                }
                if (/(E+)/.test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') + week[date.getDay() + '']);
                }
                for (var k in o) {
                    if (new RegExp('(' + k + ')').test(fmt)) {
                        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
                    }
                }
                return fmt;
            },
            calInterval(startDate, endDate) {
                let start = new Date(startDate.replace(/-/g, '/')).getTime();
                let end = new Date(endDate.replace(/-/g, '/')).getTime();
                return (end - start) / (24 * 60 * 60 * 1000);
            },
            getMonthRange(m) {
                let y = new Date().getFullYear();
                let firstDay = new Date(y, m - 1);
                let lastDay = new Date(new Date(y, m).valueOf() - 60 * 60 * 1000 * 24);
                return [this.formatDate(firstDay, 'YYYY-MM-DD'), this.formatDate(lastDay, 'YYYY-MM-DD')];
            },
            getCurrentMonthRange() {
                return this.getMonthRange(new Date().getMonth() + 1);
            },
            addDateOfDay(date, days) {
                let d = new Date();
                if (date) {
                    d = new Date(date);
                }
                d.setDate(d.getDate() + days);
                let m = d.getMonth() + 1;
                return this.formatDate(d.getFullYear() + '-' + m + '-' + d.getDate(), 'YYYY-MM-DD');
            },
            addDateOfHour(date, hour) {
                let d = new Date();
                if (date) {
                    d = new Date(date);
                }
                d.setHours(d.getHours() + hour);
                return this.formatDate(d, 'YYYY-MM-DD HH:mm:ss');
            },
            formatSecondsToHour(value) {
                var theTime = parseFloat(value); //parseFloat(value / 1000);// 秒
                var theTime1 = 0; // 分
                var theTime2 = 0; // 小时
                if (theTime > 60) {
                    theTime1 = parseInt(theTime / 60);
                    theTime = parseInt(theTime % 60);
                    if (theTime1 > 60) {
                        theTime2 = parseInt(theTime1 / 60);
                        theTime1 = parseInt(theTime1 % 60);
                    }
                }
                var result = '' + this.prefixInteger(parseInt(theTime), 2);
                if (theTime1 > 0) {
                    result = '' + this.prefixInteger(parseInt(theTime1), 2) + ':' + result;
                } else {
                    result = '' + this.prefixInteger(1, 2) + ':';
                }
                result = '' + this.prefixInteger(parseInt(theTime2), 2) + ':' + result;
                return result;
            },
            filterDateRangeValue(val, isDate) {
                if (val && val.length > 0) {
                    let e = self.$common.addDateOfDay(val[1], 1);
                    if (isDate) {
                        e = val[1];
                    }
                    return { start: val[0], end: e };
                }
                return { start: null, end: null };
            },
            getUserAuthData() {
                if (this.userAuthData) {
                    return this.userAuthData;
                }
                let data = JSON.parse(this.getStorage().getItem('user-auth'));
                if (!data) {
                    self.$common.removeUserAuthData();
                    location.href = '/#/';
                    return;
                }
                return data;
            },
            loadRouter(router) {
                let userAuthData = this.getUserAuthData();
                if (userAuthData) {
                    let routes = [];
                    RouterUtils(routes, userAuthData.authData);
                    router.addRoutes(routes);
                }
            },
            getUser() {
                let realName = self.$common.getCookie('v_nn');
                let token = self.$common.getCookie('v_tu');
                return {
                    realName: realName,
                    token: token,
                };
            },
            getCookie(name) {
                var strcookie = document.cookie; //获取cookie字符串
                var arrcookie = strcookie.split('; '); //分割
                //遍历匹配
                for (var i = 0; i < arrcookie.length; i++) {
                    var arr = arrcookie[i].split('=');
                    if (arr[0] == name) {
                        return arr[1];
                    }
                }
                return '';
            },
            delCookie(name) {
                var exp = new Date();
                exp.setTime(exp.getTime() - 1);
                var cval = getCookie(name);
                if (cval != null) document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
            },
            hasAuth(router) {
                let userAuthData = this.getUserAuthData();
                // 未登录：登录页面，登录：首页
                if (router.path === '/') {
                    return userAuthData ? true : '/login';
                }
                // 判断是否是合法的路由
                if (!router.name) {
                    return userAuthData ? '/noauth' : '/login';
                }
                // 可以匿名访问的页面，匿名访问的路由只能是硬编码到src/common/router/index.js
                if (router.meta.anonymous) {
                    return router.meta.anonymous == true ? true : '/login';
                }
                // 判断是否有权限
                if (userAuthData) {
                    // 判断数据是否存在，存在去cache中的，不存在递归获取
                    if (!this.userAuthListData) {
                        // 递归获取权限数据集合
                        this.buildUserAuthListData(userAuthData.authData, (this.userAuthListData = []));
                    }
                    for (let item of this.userAuthListData) {
                        if (item.name === router.name) {
                            return true;
                        }
                    }
                }
                return userAuthData ? '/noauth' : '/login';
            },
            isLogin() {
                return this.getUserAuthData() ? true : false;
            },
            rememberMe(val) {
                localStorage.setItem('remember_me', val);
            },
            logout(msg, path) {
                this.confirm(msg, () => {
                    self.$common.removeUserAuthData();
                    if (path) {
                        location.href = '/#/' + path;
                    } else {
                        location.href = '/#';
                    }
                });
            },
            isEmptyObject(val) {
                for (let t in val) {
                    if (t) {
                        return !1;
                    }
                }
                return !0;
            },
            objectAttr(obj, attrs, i) {
                let attr = attrs[i];
                if (obj[attr]) {
                    if (i < attrs.length - 1) {
                        return this.objectAttr(obj[attr], attrs, i + 1);
                    } else {
                        return obj[attr];
                    }
                } else {
                    return null;
                }
            },
            getSystemTime() {
                let sysTime = sessionStorage.getItem('systemTime');
                if (sysTime) {
                    return new Date(parseInt(sysTime));
                }
                return new Date();
            },
            saveSystemTime(data) {
                sessionStorage.setItem('systemTime', data);
            },
            isBlank(val) {
                if (!val) {
                    return true;
                }
                if (!(val + '').trim()) {
                    return true;
                }
                if (parseFloat(val) === 0) {
                    return true;
                }
                return false;
            },
            Base64() {
                // private property
                const _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

                // private method for UTF-8 encoding
                let _utf8_encode = function (string) {
                    string = string.replace(/\r\n/g, '\n');
                    var utftext = '';
                    for (var n = 0; n < string.length; n++) {
                        var c = string.charCodeAt(n);
                        if (c < 128) {
                            utftext += String.fromCharCode(c);
                        } else if (c > 127 && c < 2048) {
                            utftext += String.fromCharCode((c >> 6) | 192);
                            utftext += String.fromCharCode((c & 63) | 128);
                        } else {
                            utftext += String.fromCharCode((c >> 12) | 224);
                            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                            utftext += String.fromCharCode((c & 63) | 128);
                        }
                    }
                    return utftext;
                };

                // private method for UTF-8 decoding
                let _utf8_decode = function (utftext) {
                    var string = '';
                    var i = 0;
                    var c = (c1 = c2 = 0);
                    while (i < utftext.length) {
                        c = utftext.charCodeAt(i);
                        if (c < 128) {
                            string += String.fromCharCode(c);
                            i++;
                        } else if (c > 191 && c < 224) {
                            c2 = utftext.charCodeAt(i + 1);
                            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                            i += 2;
                        } else {
                            c2 = utftext.charCodeAt(i + 1);
                            c3 = utftext.charCodeAt(i + 2);
                            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                            i += 3;
                        }
                    }
                    return string;
                };
                // public method for encoding
                return {
                    encode(input) {
                        var output = '';
                        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
                        var i = 0;
                        input = _utf8_encode(input);
                        while (i < input.length) {
                            chr1 = input.charCodeAt(i++);
                            chr2 = input.charCodeAt(i++);
                            chr3 = input.charCodeAt(i++);
                            enc1 = chr1 >> 2;
                            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                            enc4 = chr3 & 63;
                            if (isNaN(chr2)) {
                                enc3 = enc4 = 64;
                            } else if (isNaN(chr3)) {
                                enc4 = 64;
                            }
                            output = output + _keyStr.charAt(enc1) + _keyStr.charAt(enc2) + _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
                        }
                        return output;
                    },
                    // public method for decoding
                    decode(input) {
                        var output = '';
                        var chr1, chr2, chr3;
                        var enc1, enc2, enc3, enc4;
                        var i = 0;
                        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
                        while (i < input.length) {
                            enc1 = _keyStr.indexOf(input.charAt(i++));
                            enc2 = _keyStr.indexOf(input.charAt(i++));
                            enc3 = _keyStr.indexOf(input.charAt(i++));
                            enc4 = _keyStr.indexOf(input.charAt(i++));
                            chr1 = (enc1 << 2) | (enc2 >> 4);
                            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                            chr3 = ((enc3 & 3) << 6) | enc4;
                            output = output + String.fromCharCode(chr1);
                            if (enc3 != 64) {
                                output = output + String.fromCharCode(chr2);
                            }
                            if (enc4 != 64) {
                                output = output + String.fromCharCode(chr3);
                            }
                        }
                        output = _utf8_decode(output);
                        return output;
                    },
                };
            },
            compress(base64String, w, quality) {
                var getMimeType = function (urlData) {
                    var arr = urlData.split(',');
                    var mime = arr[0].match(/:(.*?);/)[1];
                    // return mime.replace("image/", "");
                    return mime;
                };
                var newImage = new Image();
                var imgWidth, imgHeight;

                var promise = new Promise((resolve) => (newImage.onload = resolve));
                newImage.src = base64String;
                return promise.then(() => {
                    imgWidth = newImage.width;
                    imgHeight = newImage.height;
                    var canvas = document.createElement('canvas');
                    var ctx = canvas.getContext('2d');
                    if (Math.max(imgWidth, imgHeight) > w) {
                        if (imgWidth > imgHeight) {
                            canvas.width = w;
                            canvas.height = (w * imgHeight) / imgWidth;
                        } else {
                            canvas.height = w;
                            canvas.width = (w * imgWidth) / imgHeight;
                        }
                    } else {
                        canvas.width = imgWidth;
                        canvas.height = imgHeight;
                    }
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);
                    var base64 = canvas.toDataURL(getMimeType(base64String), quality);
                    console.log(base64);
                    return base64;
                });
            },
           
        };
    },
};

export default utils;
