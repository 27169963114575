import LazyLoadingRouter from './lazy-loading-router'

export default (routers, data) => {
  //这里之所以要重新遍历一下，是因为，通常我们动态路由的时候，是获取服务端数据，这个component属性是一个字符串，或者可能连字段名都是其他的key
  //所以这里要做一些转换
  generaComponents(routers, data)
}

function generaComponents (routers, data) {
  data.forEach((item) => {
    let menu = Object.assign({}, item)
    menu['props'] =  (route) => ({ ...route.query })
    delete menu.children
    menu.component = LazyLoadingRouter(menu.component)
    if (item.children && item.children.length > 0) {
      // menu.children = []
      // generaComponents(menu.children, item.children)
      generaComponents(routers, item.children)
    }
    routers.push(menu)
  })
}
