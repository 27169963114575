import Crypto from './crypto';

const filter = function (str) {
    // 特殊字符转义
    str += ''; // 隐式转换
    str = str.replace(/%/g, '%25');
    str = str.replace(/\+/g, '%2B');
    // str = str.replace(/ /g, '%20');
    str = str.replace(/\//g, '%2F');
    str = str.replace(/\?/g, '%3F');
    str = str.replace(/&/g, '%26');
    str = str.replace(/\=/g, '%3D');
    str = str.replace(/#/g, '%23');
    return str;
};
const formateObjToParamStr = function (paramObj) {
    const sdata = [];
    for (let attr in paramObj) {
        let val = paramObj[attr] + '';
        if (val.length < 500) {
            sdata.push(`${attr}=${filter(val)}`);
        }
    }
    return sdata.join('&');
};
const sort_ASCII = function (obj) {
    let arr = new Array();
    let num = 0;
    for (let i in obj) {
        arr[num] = i;
        num++;
    }
    var sortArr = arr.sort();
    var sortObj = {};
    for (let i in sortArr) {
        sortObj[sortArr[i]] = obj[sortArr[i]];
    }
    return sortObj;
};

const rnd = function (n) {
    let num = '';
    for (let i = 0; i < n; i++) {
        num += Math.floor(Math.random() * 10);
    }
    return num;
};

export default {
    // 获取Token
    getToken(config) {
        let action = config.url;
        let timestemp = new Date().getTime() + rnd(3);
        let params = config.params || {};
        if (config.method == 'post') {
            params = config.data;
        }
        let tokenStr = `${action}|${timestemp}|${formateObjToParamStr(sort_ASCII(params))}`;
        // 设置通用参数st
        config.params = config.params || {};
        config.params._st = timestemp;
        return Crypto.encrypt(tokenStr, timestemp, timestemp);
    },
};
