import axios from 'axios';

// const apiUrl = 'http://beta-fans.jd.com/super'
const isDebug = false;
const apiUrl = isDebug ? 'http://localhost:8080' : 'https://bj.ngrok.jkhapp.com/unipus';
let remember = localStorage.getItem('remember_me');
const uid = (remember ? localStorage : sessionStorage).getItem('auth_user_id');
const token = (remember ? localStorage : sessionStorage).getItem('auth_token');

let instance = axios.create({
  API_URL: apiUrl,
  baseURL: apiUrl + '/exp/api',
  timeout: 30000,
  headers: {
    // 'Market-Agent': 'ems-dashboard.honeywell.com/com.ems.dashboard/0.1_1/web/v.x/WebBrowser/IMEI/' + token + '/' + (uid ? uid : 0),
    //'x-requested-with': 'XMLHttpRequest',
  },
  withCredentials: false,
});
export default instance;
