import Mock from 'mockjs2';

const facility = (options) => {
    const nav = {
        success: true,
        message: '成功',
        code: 200,
        result: [
            {
                id: 'F1466AC936BEDAC56B7F43399649498A',
                name: '滨海名居-58-202',
                relationId: '355ee1ef495641d2a4ec79e5af2420fb',
            },
            {
                id: 'A12CCC9339CF2E8D1A016C1B0846E97D',
                name: '联盛大厦-其他',
                relationId: 'c47781f817e84e9ea13456dea0fa3c8e',
            },
        ],
        timestamp: 1638075490428,
    };
    return nav;
};

Mock.mock(/community\/list/, 'get', facility);
