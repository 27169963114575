<template>
    <div class="container">
        <div class="body">
            <p><b>简介</b></p>
            <p>
                当您访问或使用我们涉及隐私声明的任何网站、产品、服务和应用程序（统称为我们的“站点”）时，Honeywell International
                Inc.（“<b>霍尼韦尔”</b>或“<b>我们</b>”）会收集您的个人信息，并且是所收集信息的数据控制者。
                本隐私声明说明了我们如何收集、共享和使用借此收集的您的个人信息，以及您可以如何行使您的隐私权。
            </p>
            <p>
                当我们与您互动时，可能还会向您发布有关特定处理活动的其他隐私通知，或为您提供关于霍尼韦尔在该特定时间点如何处理您个人信息的其他选择。
            </p>
            <p>单击下方的链接之一，跳转到您感兴趣的部分：</p>
            <p>
                <a href="#zh-heading1"><b>信息的收集</b></a>
            </p>
            <p>
                <a href="#zh-heading2"><b>信息的使用</b></a>
            </p>
            <p>
                <a href="#zh-heading3"><b>我们处理信息的法律依据</b></a>
            </p>
            <p>
                <a href="#zh-heading4"><b>对信息进行匿名化处理</b></a>
            </p>
            <p>
                <a href="#zh-heading5"><b>我们保留信息的时长</b></a>
            </p>
            <p>
                <a href="#zh-heading6"><b>信息的披露</b></a>
            </p>
            <p>
                <a href="#zh-heading7"><b>跨国转移</b></a>
            </p>
            <p>
                <a href="#zh-heading8"><b>安全性</b></a>
            </p>
            <p>
                <a href="#zh-heading11"><b>您的权利</b></a>
            </p>
            <p>
                <a href="#zh-heading12"><b>儿童的信息</b></a>
            </p>
            <p>
                <a href="#zh-heading13"><b>与其他网站的链接</b></a>
            </p>
            <p>
                <a href="#zh-heading14"><b>联系我们</b></a>
            </p>
            <p>
                <a href="#zh-heading14"><b>&nbsp;</b></a>
            </p>
            <p>
                <b><a id="zh-heading1"></a>信息的收集</b>
            </p>
            <ul>
                <li>
                    <b>直接向您收集的信息</b>。为了按照您的请求提供服务或进行交易，我们可能会请您提供个人信息。
                    与我们联系或合作（例如提供意见、查询事宜、有客户支持请求或访问站点），或者为使用我们的站点而注册帐户时，也可能需要您向我们提供个人信息。
                    这些个人信息包括：
                    <ul>
                        <li>
                            <b>身份数据，</b
                            >例如您的姓名、职务、公司/组织名称、电子邮件地址、电话号码和实际地址（包括国家/地区、省/自治区/直辖市、市、街道和邮政编码）；
                        </li>
                        <li><b>注册数据，</b>您为使用我们的站点而注册帐户时提供的信息，包括用户名和密码；</li>
                        <li><b>招聘数据，</b>包括在申请工作或查询时提交的信息（如下所述）；</li>
                        <li>
                            <b>业务联系数据，</b
                            >例如与第三方组织（如企业、公司、合伙企业、独资企业、非营利组织或政府机构）的其他员工、所有者、董事、高级职员或承包商相关的信息。
                        </li>
                        <li><b>营销和通讯数据，</b>包括您的购买偏好，以及您对我们出版物的订阅；</li>
                        <li><b>财务信息，</b>包括信用卡或其他财务帐户信息，旨在方便您购买我们的站点上可能提供的公司产品和服务；</li>
                        <li>
                            <b>出口管制信息，</b>例如您的国籍、公民身份以及所在国家/地区，以便我们可依据出口管制法规确定您是否有资格获知关于某些技术的信息；
                        </li>
                        <li><b>交易数据，</b>包括与我们的产品和服务相关的查询和订单，以及您的收付款详细信息，包括购买订单历史记录和促进付款交易所需的信息；</li>
                        <li><b>活动注册信息，</b>包括您在填写电子注册表单时提供的信息；以及</li>
                        <li><b>您的反馈，</b>包括您对我们的站点以及产品和服务整体上的调查结果、建议或其他反馈。</li>
                    </ul>
                </li>
            </ul>
            <p>如果您没有提供请求的信息，我们可能无法为您提供请求的服务或完成您的交易。</p>
            <ul>
                <li>
                    <b>我们自动收集的与您相关的信息</b>。我们会使用 Cookie 和类似跟踪技术来收集有关您访问我们站点的某些信息。
                    您有阻止我们收集这些信息的合法权利，我们将一直尊重您的此等权利。 以此方式收集的数据包括：
                    <ul>
                        <li>
                            <b>使用数据，</b
                            >包括以下相关信息：您如何使用我们的站点、查看的页面、传送的字节数、单击的链接、访问的资料、访问站点的日期和时间、您从哪个网站链接到我们的某个站点，以及在站点内执行的其他操作；以及
                        </li>
                        <li><b>技术数据，</b>例如 Internet 协议 (IP) 地址、浏览器类型、功能和语言，以及操作系统。</li>
                    </ul>
                </li>
            </ul>
            <p>
                有关我们在霍尼韦尔站点上使用的 Cookie 和其他类似跟踪技术，请参阅我们的
                <a href="https://www.honeywell.com/us/en/cookie-notice#language3">Cookie 声明</a>。
            </p>
            <ul>
                <li>
                    <b>地理位置信息</b>。对于某些站点，经您同意后，我们可能会收集您移动设备的准确、实时位置（即地理位置）。
                    此信息将仅用于方便您使用站点，包括我们的服务提供商可能会出于代表我们提供服务而处理此信息。
                    您给予同意后，也可以更改此同意，方法是在您移动设备的设置中，管理“定位服务”首选项。
                </li>
                <li>
                    <b>从第三方收集的信息</b
                    >。依据适用的法律，我们可能会从第三方（例如社交媒体网站和应用程序）处收集与您相关的个人信息，并将此类信息与我们已有的信息结合使用，来帮助我们根据您的偏好并出于本隐私声明中所列的其他目的，改进和定制我们的站点。我们还可能会从您的雇主或其他第三方处收集您的业务联系信息，以促进或参与传统业务活动和类似的管理事项。
                </li>
            </ul>
            <p>
                <b><a id="zh-heading2"></a>信息的使用</b>
            </p>
            <p>通过霍尼韦尔站点或您联系我们时收集的个人信息，可能会用于以下目的，包括：</p>
            <ul>
                <li>
                    <b>业务联系数据</b>。 霍尼韦尔将收集和使用业务联系数据进行沟通或交易，包括就产品或服务开展尽职调查，或者提供或接收产品或服务。
                    例如，霍尼韦尔可能会收集和使用此类业务联系数据来促进和管理订单、合同、保修、维护和类似业务职能；用于信用分析和收集；帮助达成我们自己内部的合规性和法律要求；以及（如有必要）捍卫我们的利益和主张。
                </li>
                <li>
                    <b>服务和交易</b
                    >。我们可能会使用您的个人信息按您的请求提供服务或进行交易，包括但不限于提供有关您已购买或以其他方式使用的公司产品或服务的信息、注册所购买的产品、处理产品订单、处理保修索赔、更换产品手册、回应客户服务请求以及方便使用我们的站点。
                </li>
                <li>
                    <b>管理和保护我们的业务与站点</b>。我们可能会使用您的个人信息管理和保护我们的业务和站点，包括疑难解答、系统维护、支持以及数据的报告和托管。
                </li>
                <li>
                    <b>改进我们的业务、站点、产品和服务</b
                    >。我们可能会使用您的个人信息执行业务分析，或将其用于其他目的，来帮助我们发展业务、站点、产品和服务（包括新产品和服务）和改进其质量，例如根据您的特定偏好或兴趣定制我们的站点。
                </li>
                <li>
                    <b>营销</b
                    >。依据适用的法律和法规，我们可能会使用您的个人信息向您告知您可能感兴趣的产品或服务，或以其他方式向您告知产品和服务、活动和新闻、调查、特惠以及相关主题。
                    您可以随时选择不接收通过电子邮件发送的营销资讯，而无需承担任何费用，方法是使用我们发送给您的任何电子邮件营销资料中的“取消订阅”链接，或者使用本隐私声明中所列的联系信息联系我们。
                    根据您所在的国家/地区，您还可以选择不接收其他类型的营销资讯；有关详情，请使用本隐私声明中所列的联系信息联系我们。在某些司法管辖区，个人（如加利福尼亚州居民）有权请求有关我们向第三方披露个人信息以用于第三方直接营销的特定信息。
                    想了解更多我们出于营销目的而采取的数据披露做法，请按照下文的说明与我们联系。
                </li>
                <li>
                    <b>根据兴趣推送广告</b>。 我们收集您的在线活动数据并明确您的兴趣所在，从而提供您最兴趣的广告。 您可以按照我们的
                    <a href="https://www.honeywell.com/us/en/cookie-notice#language3">Cookie 声明</a>所述，选择不接收霍尼韦尔根据兴趣提供的广告。
                </li>
                <li>
                    <b>财务激励和忠诚度计划</b>。 我们收集和使用个人信息来维护和管理我们的财务激励与忠诚度计划。
                    我们使用您在这些计划中提供的个人信息来提供特殊奖励、跟踪您的计划状态，并促进用计划积分兑换产品、促销材料、培训研讨会和其他项目。
                    在法律允许的范围内，我们还可能会出于本隐私声明中所列的任何其他目的，将您提供的任何个人信息用于财务激励计划。
                    请查看您要注册或参与的每个财务激励计划的条款和条件，因为这些条款和条件可能严格限于适用计划。
                    如果您同意参与此类计划，您可以随时撤销此项同意，方法是使用本隐私声明在末尾提供的详细联系信息或根据适用计划的条款和条件中所列的说明与我们联系。
                </li>
                <li>
                    <b>就职申请</b
                    >。在工作申请或查询中，您可能会向我们提供有关您自己的信息，例如简历、职业推荐信、有关教育和工作背景的信息以及有关职业培训和证书的信息。我们和我们集团的成员可能会将此类信息用于雇佣考虑、背景调查和就业资格认定，以及与您的申请相关的任何单独隐私声明或其他适用通知中所列的其他目的。
                    我们可能会使用第三方（例如招聘机构）或社交媒体平台来征集、收集和保留就职申请。 有关更多信息，请查看我们<a
                        href="https://www.honeywell.com/us/en/applicant-privacy-notice"
                        >的求职者隐私声明</a
                    >。<br />
                </li>
                <li>
                    <b>调查和分析</b
                    >。我们可能会使用您的个人信息进行研究和分析，来帮助我们分析您的购买偏好、确定最能满足您要求的产品和服务，并衡量我们向您提供的广告的效果。
                </li>
                <li>
                    <b>强制实施：</b>我们可能会将收集的个人信息用于检测、阻止和应对欺诈、知识产权侵犯、违反我们的条款和条件、违反法律或其他滥用我们站点的行为。
                </li>
            </ul>
            <p>
                在法律允许的范围内，我们可能会将通过我们的站点收集的信息与我们拥有的与您相关的其他信息（例如与您使用我们的产品和服务相关的信息）结合使用，以在您与我们交互时为您提供改进的一致客户体验，或将其用于本隐私声明中所列的其他目的。
            </p>
            <p>
                <b><a id="zh-heading3"></a>我们处理信息的法律依据</b>
            </p>
            <p>
                在法律要求的情况下，我们将会确保根据法律依据处理您的个人信息。
                在大多数情况下，我们的法律依据取决于相关个人信息以及我们收集个人信息所在的特定环境。
            </p>
            <ul>
                <li><b>合同： </b>要正常使用我们为您提供的服务，或者在提供此类服务之前（应您请求）采取措施或预计将提供此类服务，则必需处理您的个人信息；</li>
                <li><b>法律义务： </b>我们出于履行法律义务（包括遵循适用的法律、法规、政府和准政府请求、法院命令或传票）而必需处理您的个人信息；</li>
                <li><b>同意： </b>处理建立在您同意出于一个或多个指定目的（例如营销）处理您的个人信息的基础之上；</li>
                <li><b>合法利益： </b>处理对于行使我们的合法利益是必需的，例如为了我们客户的利益，开发和改进我们的站点、产品和/或服务；或者</li>
                <li>
                    <b>重要利益： </b
                    >处理对于保护您的重要利益是必需的（例如，如果您在霍尼韦尔地点参加会议，则我们可能出于保护您的健康和安全的原因来处理您的个人信息）。<br />
                </li>
            </ul>
            <p>
                如果您对我们收集和使用您的个人信息的法律依据有任何疑问或需要进一步的信息，请发送电子邮件至
                <a href="mailto:HoneywellPrivacy@honeywell.com">HoneywellPrivacy@honeywell.com</a>
            </p>
            <p>
                <b><a id="zh-heading4"></a>对信息进行匿名化处理</b>
            </p>
            <p>
                依据适用的法律，我们可能会匿名处理您的个人信息，以便该信息不再能够用于识别您的身份。 匿名信息不属于个人信息，因此不受本隐私声明的制约。
                我们可能会出于任何目的收集、使用、整合和共享匿名信息。
            </p>
            <p>
                <b><a id="zh-heading5"></a>我们保留信息的时长</b>
            </p>
            <p>
                我们存储您的个人信息的时长因处理目的而异。
                例如，在您作为霍尼韦尔客户的时间内，我们会一直存储您的个人信息，以便为您提供产品和服务，或为您请求的交易提供便利；或者如果您通过我们的站点提交求职申请，除非法律另有要求，否则我们将根据提供的与您的申请相关的隐私声明中的规定，保留您的申请详细信息。
                在其他所有情况下，我们会按照实现本隐私声明中所列的目的所需的时长存储您的个人信息，此后将匿名保存（在适用法律允许的范围内）、删除或销毁该信息。
            </p>
            <p>
                <b><a id="zh-heading6"></a>信息的披露</b>
            </p>
            <p>我们可能会依据适用的法律与包括以下方在内的所选第三方共享您的个人信息：</p>
            <ul>
                <li><b>我们的集团公司</b>。出于本隐私声明中所述的目的，我们可能会根据合理需要，与我们的关联公司和集团公司共享您的个人信息。</li>
                <li>
                    <b>服务提供商</b
                    >。我们可能会与签订了合同代表我们提供服务（例如托管网站、开展调查、处理交易、分析我们的站点和执行分析）的公司共享您的个人信息，以便提高我们的业务、站点、产品和服务的质量。
                    我们要求这些服务提供商保护您的个人信息的机密性。
                </li>
                <li>
                    <b>分销商和其他可靠业务合作伙伴</b
                    >。我们可能会与分销我们的产品的第三方以及其他可靠业务合作伙伴共享您的个人信息，以用于允许这些第三方向您发送营销资讯等目的。
                    出于营销目的进行的此类个人信息共享，将依据适用的法律和法规执行。
                </li>
                <li>
                    <b>执行交易时披露</b>。在执行某些交易时，我们可能会向执行交易过程中涉及的金融机构、政府部门和运输公司或邮政机关披露您的部分或全部个人信息。
                </li>
                <li>
                    <b>收购或资产剥离时披露</b
                    >。出于战略原因或其他商业原因，可能会出现霍尼韦尔决定出售、购买、资产剥离、合并或以其他方式重组某些国家/地区内的企业的情况。
                    我们仅会披露我们所保留的有关您的合理必要信息，以便继续磋商或完成霍尼韦尔全部或部分资产的合并、收购、资产剥离或出售。
                </li>
                <li>
                    <b>出于其他原因披露</b
                    >。在以下情况下，我们可能会披露个人信息：法律规定或许可这么做；确信必须这么做才符合适用的法律规定或法律程序；保护和捍卫我们的权利或财产；在紧急情况下保护任何人的人身安全。
                </li>
            </ul>
            <p>
                <b><a id="zh-heading7"></a>跨国转移</b>
            </p>
            <p>
                霍尼韦尔是一家全球性组织，因此可能会根据适用法律，将您的个人信息转移到世界各地或在这些地方保存、存储或使用您的个人信息，例如美国、墨西哥和印度。
                这意味着，当我们收集您的个人信息时，它可能会在其数据保护法律与您所在国家/地区的法律不同的国家/地区进行处理。
                但是，霍尼韦尔将始终采取合理的措施来保护您的隐私，并提供与您所在国家/地区相当的个人信息保护水平，其中包括执行欧盟委员会的标准合同条款。
                有关为保护您的个人信息所采取的相应安全保障措施的更多信息，请通过
                <a href="mailto:Honeywellprivacy@honeywell.com">Honeywellprivacy@honeywell.com</a> 联系我们。
            </p>
            <p>
                <b><a id="zh-heading8"></a>安全性</b>
            </p>
            <p>
                我们致力于保护您的个人信息的安全。
                我们使用技术和组织措施来提供与处理您的个人信息的风险相适应的安全级别。有关霍尼韦尔如何保护您的个人信息的更多信息，请通过
                <a href="mailto:Honeywellprivacy@honeywell.com">Honeywellprivacy@honeywell.com</a> 联系我们。
            </p>
            <p>
                <b><a id="zh-heading11"></a>您的权利</b>
            </p>
            <p>根据适用法律，您对您的个人信息可能享有某些权利。</p>
            <ul>
                <li>您可能有权<b>访问、更正、更新</b>或要求<b>删除</b>您的个人信息。</li>
                <li>您可以<b>反对</b>处理您的个人信息，要求我们<b>限制</b>处理您的个人信息或请求您的个人信息的<b>可移植性</b>。</li>
                <li>您可能有<b>权要求不完全基于自动化处理（包括分析）来做出有关您的、</b>会对您产生法律影响或对您有重大影响的决定。</li>
                <li>
                    同样，如果我们在征得您同意的情况下收集并处理了您的个人信息，<b>您可以随时撤销同意</b>。
                    撤销同意不会影响我们在您撤销之前进行的任何处理的合法性，也不会影响根据同意以外的合法处理理由对您个人信息的处理。
                </li>
                <li>
                    您可能有权<b>选择不出售您的个人信息</b>。 霍尼韦尔不会将您的个人信息出售给第三方以获取利润。 但是，我们会根据本隐私声明和我们的
                    <a href="https://www.honeywell.com/us/en/cookie-notice#language3">Cookie 声明</a>，与第三方共享或以其他方式向第三方披露您的个人信息。
                    例如，我们会与第三方合作来分销我们的产品、向您提供技术帮助、运行网站分析工具和功能以及从事我们的产品和服务的营销与销售。
                    此类活动可能涉及向第三方披露您的个人信息，这些活动在某些（而非全部）数据保护法律下可能会被视为“出售”数据。
                </li>
                <li>
                    如果您认为我们在处理您的个人信息时未遵循数据保护法，您有权向您所在司法管辖区内负责监督数据保护法遵循情况的数据保护机构就我们收集和使用您的个人信息提出<b>投诉</b>。
                    例如，欧洲经济区的个人有权向主管的数据保护机构提出投诉，相关机构的列表可在以下位置访问：
                    <a href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank">https://edpb.europa.eu/about-edpb/board/members_en</a>。
                    无论如何，我们都感谢您让我们有机会为您排忧解难，因此，敬请随时与我们联系，告知您可能提出的任何投诉。
                    我们不会因个人行使其数据隐私权而歧视他们。
                </li>
            </ul>
            <p>
                我们将根据适用法律帮助您行使适用于您的权利。
                不过，即使您的个人信息没有受到数据保护法的保护，我们也可能会力求满足您对我们的数据处理活动提出的任何请求或查询。
                如果您对您的权利有疑问或希望行使您的权利，可以通过
                <a href="mailto:Honeywellprivacy@honeywell.com">Honeywellprivacy@honeywell.co</a>m 联系我们，或通过以下链接提供的交互式表单提交隐私问题：
            </p>
            <p>
                <a href="https://honeywellhub.secure.force.com/PrivacyInformationRequestForm?lang=en" target="_blank">https://honeywellhub.secure.force.com/</a>
            </p>
            <p>
                <b><a id="zh-heading12"></a>儿童的信息</b>
            </p>
            <p>
                我们的站点不面向十六 (16) 周岁以下的儿童，也不应由他们使用，我们不会有意收集、使用或出售此类个人的信息。
                如果您是您所在国家/地区的法律所理解的未成年人，请勿通过我们的站点提交任何个人信息。
            </p>
            <p>
                <b><a id="zh-heading13"></a>与其他网站的链接</b>
            </p>
            <p>
                我们的站点可能包含指向第三方网站、产品和服务的链接。
                对于这些网站、产品和服务及其政策，或它们对于您的个人信息的收集或以其他方式的处理，我们不负有任何义务或责任。
                这些第三方的做法受其自己的隐私政策约束。 我们建议您了解这些第三方的隐私政策。
            </p>
            <p>
                <b><a id="zh-heading14"></a>联系我们</b>
            </p>
            <p>
                如果您对本隐私声明或我们处理您的个人信息的方式有任何疑问，想要提出投诉，或希望联系我们的数据保护专员，请使用下面列出的联系信息与我们联系。
                我们将立即着手解决您的问题并力争提供令您满意的解决方案。
            </p>
            <p>
                <u>交互式表单</u>：<br />
                <a href="https://honeywellhub.secure.force.com/" target="_blank">https://honeywellhub.secure.force.com</a>
            </p>
            <p>
                <u>电子邮件</u>：<br />
                <a href="mailto:HoneywellPrivacy@honeywell.com">HoneywellPrivacy@honeywell.com</a>
            </p>
            <p>
                <u>邮寄地址</u>：<br />
                Honeywell Privacy<br />
                4D5<br />
                115 Tabor Road<br />
                Morris Plains, NJ 07950
            </p>
            <p>
                <u>免费电话</u>：<br />
                <a href="tel:8447748634">844 774 8634</a>
            </p>
            <p><b>本隐私声明的变更</b></p>
            <p>我们有时可能会更新本隐私声明。 在更新时，我们将会修订本隐私声明顶部“上次更新”日期，并根据法律可能的要求采取此类其他措施。</p>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {};
        },
        methods: {},
    };
</script>
<style lang="less" scoped>
    @import 'styles/core.less';
    .container {
        background: #fff;
        font-size: 18px;
        padding: 15px;
        color: #303030;
    }
    .container b {
        font-weight: bold;
    }
    p {
        font-weight: 300;
    }
    .container a {
        color: #007bc2 !important;
        font-weight: 400;
    }
</style>
