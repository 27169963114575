import Vue from 'vue';
import qs from 'qs';

export default {
    state: {
        data: [],
    },
    mutations: {
        GET_REPORT_DATA(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        request({ commit }, payLoad) {
            let params = {};
            params = Vue.$common.merge({}, params, payLoad.params);
            let httpParams = {
                url: payLoad.url,
            };
            if (payLoad.params && payLoad.params.method) {
                httpParams.method = payLoad.params.method;
                httpParams.data = params;
                httpParams.headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                };
                delete httpParams.data.method;
            } else {
                httpParams.method = 'get';
                httpParams.params = params;
            }
            if (payLoad.paramsCallback) {
                payLoad.paramsCallback(httpParams);
            }

            Vue.http(httpParams)
                .then(function (res) {
                    if (res.status === 200) {
                        Vue.$common.log({ url: payLoad.url, params: params }, res.data);
                        payLoad.callback && payLoad.callback(res.status, res.data);
                        commit('GET_REPORT_DATA', res.data);
                    } else {
                        payLoad.callback('error');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    payLoad.error && payLoad.error(error);
                });
        },
    },
};
